.--has-icon {
	vertical-align: middle;
	> * { vertical-align: middle; }
}

.icon {
	display: inline-block;
	width: 1em;
	height: 1em;
	line-height: 0;
	vertical-align: middle;
}

.icon-16 { width: 16px; height: 16px; }

.icon-18 { width: 18px; height: 18px; }
.icon-24 { width: 24px; height: 24px; }
.icon-32 { width: 32px; height: 32px; }
.icon-36 { width: 36px; height: 36px; }
.icon-64 { width: 64px; height: 64px; }

.icon-primary { fill: theme(primary, base); }
.icon-secondary { fill: theme(secondary, base); }
.icon-highlight { fill: theme(highlight, base); }
.icon-accent { fill: theme(accent, base); }
.icon-green { fill: $green; }
.icon-blue { fill: $blue; }
.icon-orange { fill: $orange; }
.icon-purple { fill: $purple; }
.icon-white { fill: $white; }
.icon-facebook { fill: $color-facebook; }
.icon-yelp { fill: $color-yelp; }


.icon-review {
	width: 118px;
	height: 30px;
	// 94 x 24
	// 118 x 30
	// 141 x 36
}

.icon_share {
	@include transform(scale(0.88));
	display: inline-block;
	padding: 12px;
	line-height: 0;
	text-decoration: none;
	border-radius: 4px;
	border: 1px solid;
	color: $white !important;
	svg { width: 30px; height: 30px; }
	&.--facebook {
		border-color: darken($color-facebook, 10%);
		background-color: $color-facebook;
	}
	&.--instagram {
		border-color: darken($color-instagram, 10%);
		background-color: $color-instagram;
	}
	&.--twitter {
		border-color: darken($color-twitter, 10%);
		background-color: $color-twitter;
	}
	&:hover {
		@include transform(scale(1));
		box-shadow: 0 8px 6px -8px rgba(0, 0, 0, 0.7);
	}
}


.icon-rate { 
	width: 30px;
	height: 30px;
}