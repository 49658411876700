a {
	font-style: normal;
	text-decoration: underline;
	color: $color-link;
	@include transition(all 300ms);
	&:hover,
	&:focus {
		color: $color-hover;
		text-decoration: none;
		@include transition(all 300ms);
	}
	&:focus {
		outline: 1px solid lighten($color-secondary, 25%);
		//box-shadow: 0 0 3px 3px rgba($color-link, 0.5);
	}
	&.mask {
		text-decoration: none;
		color: inherit;
		&:hover { color: inherit; }
	}
}

a.img-link {
	text-decoration: none;
}

a.phone {
	font-weight: $body-weight-bold;
	text-decoration: none;
	color: $color-text;
	@include transition(none);
	&:hover,
	&:focus {
		cursor: default;
		color: $color-text;
		@include transition(none);
	}
	&.phone-title {
		display: inline-block;
		@include font-size(22);
		line-height: 1em;
		font-weight: $body-weight-bolder;
		color: theme(secondary, dark) !important;
		strong {
			display: inline-block;
			margin-bottom: 3px;
			padding: 5px 15px;
			font-weight: bold;
			//text-shadow: 0 1px 1px rgba(black,0.2);
			border-radius: 50px;
			background-color: theme(secondary, dark);
			color: $white;
			svg {
				margin: -3px 5px 0 0;
				width: 21px;
				height: 21px;
				fill: theme(primary, dark);
			}
		}
		small {
			display: block;
			@include font-size(18);
			font-weight: $body-weight-bold;
			line-height: 1em;
			color: theme(primary, dark);
		}
	}
}

