.company_block {
	text-align: center;

	img { max-width: 360px; margin: 0 auto 15px; }

	@include media-breakpoint-only(md) {
		.company_logo {
			float: left;
			width: 49%;
			margin-left: 0;
		}
		.company_call,
		.company_address {
			float: right;
			width: 49%;
			text-align: right;
		}
		.list_company {
			border-top: 1px solid #ddd;
			border-bottom: 1px solid #ddd;
			padding: 0.5em;
		}
	}

	@include media-breakpoint-up(lg) {
		text-align: left;
		.company_logo { margin: 0 0 1em; }
	}

}

	.company_logo {
		margin: 0 auto 0.5em;
	}


	.company_call {
		line-height: 1.1em;
	}

		.company_call .phone {
			font-size: 32px;
			font-weight: 800;
			letter-spacing: -0.025em;
			color: $color-primary;

			@include media-breakpoint-up(lg) {
				font-size: 42px;
				letter-spacing: -0.05em;
			}

		}

		.call_emergency {
			display: inline-block;
			margin-top: 0.05em;
			font-size: 18px;
			font-weight: 600;
			letter-spacing: -0.05em;
			color: $color-danger;
		}

	.company_address {

		//@include txt--sm;
		//@include lh125;

		//.address_title { @include txt--lg; }

		@include media-breakpoint-up(lg) {
			padding: 0.5em 0.75em;
			border-left: 5px solid #ccc;
		}

	}


	.company_block .list_company {
		clear: both;
		font-weight: 600;

		li {
			display: inline-block;
			padding: 0;
			line-height: 1.25em;
			&:not(:last-child) { margin-bottom: 0.25em; }
			span {
				display: inline-block;
				padding: 0.25em 0.5em;
				background-color: rgba(0,0,0,0.7);
				color: $white;
			}
		}

		@include media-breakpoint-up(lg) {
			li {
				display: block;
			}
		}

	}	