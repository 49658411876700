	.nav-main {
		
		display: flex;
		flex-wrap: nowrap;
		align-items: flex-end;
		justify-content: center;

		.nav-item {
			display: block;

			&:not(:last-child) { padding-right: 2px; }
			&:not(:first-child) { padding-left: 2px; }

			&.active .nav-link {
				padding: 10px 18px !important;
				letter-spacing: -0.0325em;
				background: transparent !important;
				color: $color-primary;
				b {font-weight: $body-weight-bolder;}
			}

		}

		> .nav-item > .nav-link {
			border-radius: 4px 4px 0 0;
			background-color: theme(secondary, dark);
			&:hover {
				background-color: $color-secondary;
				padding: 14px 18px;
			}

		}

			.nav-link {
				@include font-size(15);
				font-weight: $body-weight-bold;
				padding: 10px 18px;
				color: $white;
			}

		.dropdown-nav {
			position: absolute;
			min-width: 200px;
			margin-top: 5px;
			padding: 15px 10px;
			border-radius: 4px;
			background-color: $color-primary;

			.nav-item:not(:last-child) {
				border-bottom: 1px dotted $white;
			}

			.nav-link:hover {
				color: theme(secondary, dark);
				.caret-push { fill: theme(secondary, dark); }
			}

			.active .nav-link {
				color: theme(highlight, base);
				font-weight: $body-weight-bolder;
				.caret-push {
					width: 0;
					margin-right: 0;
				}
			}

		}

	}