.form_quote, .widget {
  padding: 0.5em;
  border: 1px solid #ddd;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); }

@-ms-viewport {
  width: device-width; }

html {
  position: relative;
  min-height: 100%; }

body {
  min-height: 100%;
  margin: 0;
  background-color: #F5F5F5;
  color: #333333; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: 0.35em 0.75em 0.625em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

progress {
  vertical-align: baseline; }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

details {
  display: block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 10px;
  padding-left: 10px;
  width: 100%; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 10px;
  padding-left: 10px;
  width: 100%; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .wrap {
    padding-right: 0;
    padding-left: 0; }

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  -ms-box-sizing: inherit;
  -o-box-sizing: inherit;
  box-sizing: inherit; }

.wrap {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px; }

@media (min-width: 992px) {
  .content .main {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
    padding: 0 15px; }
  .content .sidebar {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    padding: 0 15px; } }

@media (min-width: 992px) {
  .content.full_width .main {
    flex: 0 0 100%;
    max-width: 100%; } }

body {
  min-width: 360px;
  background-color: #F5F5F5; }
  @media (min-width: 768px) {
    body:not(.page_front) {
      height: 100%;
      padding-bottom: 100px; }
      body:not(.page_front) .site_info {
        position: absolute;
        bottom: 0;
        width: 100%; } }

#directions,
#hours {
  outline: none !important;
  box-shadow: none !important; }

body {
  font-size: 17px;
  font-size: 1.0625rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: -0.0225em;
  line-height: 1.58824em;
  -webkit-text-size-adjust: 100%; }
  @media (min-width: 992px) {
    body {
      font-size: 18px;
      font-size: 1.125rem; } }

h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin: 45px 0 15px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: -0.0375em;
  line-height: 1em; }
  h4 small, .h4 small, h3 small, .h3 small, h2 small, .h2 small, h1 small, .h1 small {
    display: block;
    font-size: 75%;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 600;
    line-height: 1em;
    color: #888; }

h5, .h5,
h6, .h6 {
  margin: 30px 0 15px;
  font-size: 17px;
  font-size: 1.0625rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600; }

h4, .h4 {
  font-size: 19px;
  font-size: 1.1875rem; }

h3, .h3 {
  font-size: 22px;
  font-size: 1.375rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  letter-spacing: -0.0325em; }

h2, .h2 {
  font-size: 25px;
  font-size: 1.5625rem; }

h1, .h1 {
  font-size: 33px;
  font-size: 2.0625rem; }
  @media (min-width: 768px) {
    h1, .h1 {
      font-size: 38px;
      font-size: 2.375rem; } }

p {
  margin: 0 0 15px; }
  p.lead {
    font-size: 118%; }

ul, ol {
  margin: 0;
  padding: 0; }

b, strong {
  font-weight: 600; }

address {
  margin: 0 0 15px;
  font-style: normal; }

blockquote {
  margin: 0 0 1.5em;
  padding: 1em;
  font-size: 112.5%;
  line-height: 1.425em;
  border-left: 0.5rem solid; }
  blockquote > * {
    margin-bottom: 0.5em; }
  blockquote > *:last-child {
    margin-bottom: 0; }
  blockquote.bq--text {
    border-color: #333333; }
  blockquote.bq--primary {
    border-color: #1760ae; }
  blockquote.bq--secondary {
    border-color: #7eb812; }
  blockquote.bq--highlight {
    border-color: #fd7e14; }
  blockquote.bq--accent {
    border-color: #ee2927; }
  blockquote.bq--success {
    border-color: #28a745; }
  blockquote.bq--danger {
    border-color: #dc3545; }
  blockquote.bq--warning {
    border-color: #d39e00; }
  blockquote.bq--info {
    border-color: #0182C8; }
  blockquote.review {
    padding: 0;
    margin: 0 0 1em;
    font-size: 1rem;
    border: none; }

.bq-title {
  display: block;
  font-weight: 800;
  letter-spacing: -0.0275em;
  text-transform: uppercase; }
  .bq--text .bq-title {
    color: #333333; }
  .bq--primary .bq-title {
    color: #1760ae; }
  .bq--secondary .bq-title {
    color: #6ea110; }
  .bq--highlight .bq-title {
    color: #fd7e14; }
  .bq--accent .bq-title {
    color: #ee2927; }
  .bq--success .bq-title {
    color: #28a745; }
  .bq--danger .bq-title {
    color: #dc3545; }
  .bq--warning .bq-title {
    color: #d39e00; }
  .bq--info .bq-title {
    color: #0182C8; }

blockquote.bq-alt {
  margin-right: 5em;
  padding: 1.5em;
  border-radius: 6px;
  border: 4px solid #333333;
  background-color: #fff; }
  blockquote.bq-alt.bq--primary {
    border-color: #1760ae; }
  blockquote.bq-alt.bq--secondary {
    border-color: #7eb812; }
  blockquote.bq-alt.bq--highlight {
    border-color: #fd7e14; }
  blockquote.bq-alt.bq--accent {
    border-color: #ee2927; }
  blockquote.bq-alt.bq--success {
    border-color: #28a745; }
  blockquote.bq-alt.bq--danger {
    border-color: #dc3545; }
  blockquote.bq-alt.bq--warning {
    border-color: #ffc107; }
  blockquote.bq-alt.bq--info {
    border-color: #0182C8; }

hr {
  display: block;
  width: 100%;
  height: 1px;
  margin: 2em auto;
  border: none;
  background-color: #bbb; }
  hr.hr-inset {
    margin: 2em auto;
    border: none;
    background-color: #bbb;
    box-shadow: 0 1px 0 0 #fff; }
  .content_text hr {
    margin: 1em 0 !important; }

a {
  font-style: normal;
  text-decoration: underline;
  color: #0b8dd7;
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -ms-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms; }
  a:hover, a:focus {
    color: #21a7f4;
    text-decoration: none;
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -ms-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms; }
  a:focus {
    outline: 1px solid #bbef5b; }
  a.mask {
    text-decoration: none;
    color: inherit; }
    a.mask:hover {
      color: inherit; }

a.img-link {
  text-decoration: none; }

a.phone {
  font-weight: 600;
  text-decoration: none;
  color: #333333;
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none; }
  a.phone:hover, a.phone:focus {
    cursor: default;
    color: #333333;
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none; }
  a.phone.phone-title {
    display: inline-block;
    font-size: 22px;
    font-size: 1.375rem;
    line-height: 1em;
    font-weight: 800;
    color: #6ea110 !important; }
    a.phone.phone-title strong {
      display: inline-block;
      margin-bottom: 3px;
      padding: 5px 15px;
      font-weight: bold;
      border-radius: 50px;
      background-color: #6ea110;
      color: #fff; }
      a.phone.phone-title strong svg {
        margin: -3px 5px 0 0;
        width: 21px;
        height: 21px;
        fill: #114781; }
    a.phone.phone-title small {
      display: block;
      font-size: 18px;
      font-size: 1.125rem;
      font-weight: 600;
      line-height: 1em;
      color: #114781; }

.list {
  margin: 0 0 1em;
  padding: 0 0 0 1.2em; }
  .list li:not(:last-child) {
    margin-bottom: 0.25em; }

.list-inline, .nav, .list-unstyled {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }

.list-inline li {
  display: inline-block; }

@media (min-width: 768px) {
  .list-wrap {
    display: flex;
    flex-wrap: wrap; }
    .list-wrap.wrap-2 .list {
      padding: 0 25px; } }

.--has-icon {
  vertical-align: middle; }
  .--has-icon > * {
    vertical-align: middle; }

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 0;
  vertical-align: middle; }

.icon-16 {
  width: 16px;
  height: 16px; }

.icon-18 {
  width: 18px;
  height: 18px; }

.icon-24 {
  width: 24px;
  height: 24px; }

.icon-32 {
  width: 32px;
  height: 32px; }

.icon-36 {
  width: 36px;
  height: 36px; }

.icon-64 {
  width: 64px;
  height: 64px; }

.icon-primary {
  fill: #1760ae; }

.icon-secondary {
  fill: #7eb812; }

.icon-highlight {
  fill: #fd7e14; }

.icon-accent {
  fill: #ee2927; }

.icon-green {
  fill: #42aa14; }

.icon-blue {
  fill: #0c84ea; }

.icon-orange {
  fill: #ff8400; }

.icon-purple {
  fill: #cc00ff; }

.icon-white {
  fill: #fff; }

.icon-facebook {
  fill: #4267B2; }

.icon-yelp {
  fill: #BF2519; }

.icon-review {
  width: 118px;
  height: 30px; }

.icon_share {
  -webkit-transform: scale(0.88);
  -moz-transform: scale(0.88);
  -o-transform: scale(0.88);
  transform: scale(0.88);
  display: inline-block;
  padding: 12px;
  line-height: 0;
  text-decoration: none;
  border-radius: 4px;
  border: 1px solid;
  color: #fff !important; }
  .icon_share svg {
    width: 30px;
    height: 30px; }
  .icon_share.--facebook {
    border-color: #34518d;
    background-color: #4267B2; }
  .icon_share.--instagram {
    border-color: #a72985;
    background-color: #CD36A4; }
  .icon_share.--twitter {
    border-color: #0c85d0;
    background-color: #1DA1F2; }
  .icon_share:hover {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    box-shadow: 0 8px 6px -8px rgba(0, 0, 0, 0.7); }

.icon-rate {
  width: 30px;
  height: 30px; }

img {
  display: block;
  width: 100%;
  height: auto;
  border-style: none; }

.img-main {
  margin: 0 0 15px;
  max-width: 360px; }

.img-coupon {
  max-width: 640px; }

.img-about {
  width: 150px; }

.img-auto {
  width: auto; }

.img-inline {
  display: inline; }

.img-thumbnail {
  padding: 5px;
  border: 1px solid #ddd;
  background-color: #fff; }

@media (min-width: 768px) {
  .img--right,
  .img--left {
    clear: both;
    width: 45%; } }

@media (min-width: 768px) {
  .img--right {
    float: right;
    margin: 0 0 15px 15px; } }

@media (min-width: 768px) {
  .img--left {
    float: left;
    margin: 0 15px 15px 0; } }

.img--wide {
  margin: 0 0 15px;
  width: 100%;
  max-width: none; }

.img--auto {
  max-width: auto; }
  @media (min-width: 576px) {
    .img--auto {
      width: auto;
      height: auto; } }

.img-captioned {
  padding: 8px;
  border: 1px solid #ccc;
  background-color: #fff;
  margin-bottom: 15px; }
  .img-captioned img {
    padding: 0;
    border: none;
    margin-bottom: 0; }
  .img-captioned span {
    display: block;
    max-width: 360px;
    margin: 10px 5px 2px;
    font-size: 14px;
    font-size: 0.875rem;
    font-style: italic;
    line-height: 1.325em; }

div.img-captioned {
  max-width: 360px; }
  div.img-captioned img {
    width: 100%; }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 15px;
  line-height: 1em;
  border-spacing: 0;
  border-collapse: collapse;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }
  .table * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  .table th,
  .table td {
    padding: 0.5em 0.75em;
    vertical-align: top;
    border-top: 1px solid #ccc; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #ccc; }
  .table tbody + tbody {
    border-top: 2px solid #ccc; }
  @media (min-width: 768px) {
    .table.--right {
      display: block;
      width: 40%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      float: right; } }

.table-bordered {
  border: 2px solid #ccc; }
  .table-bordered th, .table-bordered td {
    border: 1px solid #ccc; }
  .table-bordered thead th, .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f3f3f3; }

.table-hover tbody tr:hover {
  background-color: #e5e5e5;
  cursor: pointer; }

@media (max-width: 767px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive > .table-bordered {
      border: 0; } }

.embed_responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden; }
  .embed_responsive.embed_responsive_16by9 {
    padding-bottom: 56.25%; }
  .embed_responsive.embed_responsive_4by3 {
    padding-bottom: 75%; }
  .embed_responsive .embed_responsive_item, .embed_responsive embed, .embed_responsive iframe, .embed_responsive object, .embed_responsive video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0; }

.site_alert > .alert:first-of-type {
  margin-top: 1em; }

.site_alert > .alert:last-of-type {
  margin-bottom: 0; }

.alert {
  border: 1px solid #c4c4c4;
  background-color: white;
  color: #333333;
  margin-bottom: 1em;
  padding: 1em;
  border-radius: 3px; }
  .alert strong, .alert a {
    color: #b7b7b7; }
  .alert strong {
    font-weight: 800; }

.alert-primary {
  border: 1px solid #114781;
  background-color: #9fc7f2;
  color: #333333; }
  .alert-primary strong, .alert-primary a {
    color: #0e3b6a; }

.alert-secondary {
  border: 1px solid #5e8a0d;
  background-color: #d8f6a0;
  color: #333333; }
  .alert-secondary strong, .alert-secondary a {
    color: #4e720b; }

.alert-highlight {
  border: 1px solid #dc6502;
  background-color: #ffedde;
  color: #333333; }
  .alert-highlight strong, .alert-highlight a {
    color: #c35a02; }

.alert-accent {
  border: 1px solid #d21210;
  background-color: #fde4e4;
  color: #333333; }
  .alert-accent strong, .alert-accent a {
    color: #ba100f; }

.alert-success {
  border: 1px solid #1e7e34;
  background-color: #afecbd;
  color: #333333; }
  .alert-success strong, .alert-success a {
    color: #19692c; }

.alert-danger {
  border: 1px solid #bd2130;
  background-color: #fae3e5;
  color: #333333; }
  .alert-danger strong, .alert-danger a {
    color: #a71d2a; }

.alert-info {
  border: 1px solid #016195;
  background-color: #97dafe;
  color: #333333; }
  .alert-info strong, .alert-info a {
    color: #01517c; }

.alert-warning {
  border: 1px solid #d39e00;
  background-color: #fff4d3;
  color: #333333; }
  .alert-warning strong, .alert-warning a {
    color: #ba8b00; }

.alert-link {
  border: 1px solid #096da6;
  background-color: #b3e1fb;
  color: #333333; }
  .alert-link strong, .alert-link a {
    color: #075d8e; }

.announce {
  padding: 12px 0;
  border-bottom: 0.25rem solid #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
  background-color: #7eb812;
  color: #fff; }
  .announce p {
    margin-bottom: 0.5em;
    font-weight: 800;
    text-transform: uppercase; }
    .announce p:last-child {
      margin-bottom: 0; }

[class*="announce_"] {
  text-align: center; }
  @media (min-width: 768px) {
    [class*="announce_"] p {
      float: left;
      margin: 0; } }

@media (min-width: 768px) {
  .announce_2 p {
    width: 50%; } }

@media (min-width: 768px) {
  .announce_3 p {
    width: 33.33333%; } }

@media (min-width: 768px) {
  .announce_4 p {
    width: 25%; } }

.btn {
  display: inline-block;
  padding: 12px 15px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 1em;
  text-decoration: none;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border: none;
  background-color: #0b8dd7;
  color: #fff !important;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -ms-transition: all 200ms;
  -o-transition: all 200ms;
  transition: all 200ms; }
  .btn.--has-icon svg {
    margin: -2px 5px 0 0; }
  .btn b {
    font-weight: 800;
    display: inline-block; }
  .btn:not(.btn-submit):not(.no-grow) {
    -webkit-transform: scale(0.96);
    -moz-transform: scale(0.96);
    -o-transform: scale(0.96);
    transform: scale(0.96); }
    .btn:not(.btn-submit):not(.no-grow):hover {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1); }
  .btn:hover {
    background-color: #3ab1f5;
    box-shadow: 0 15px 10px -15px rgba(0, 0, 0, 0.4);
    color: #fff;
    cursor: pointer;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
    -webkit-transition: all 200ms;
    -moz-transition: all 200ms;
    -ms-transition: all 200ms;
    -o-transition: all 200ms;
    transition: all 200ms; }

.btn--sm {
  font-size: 85%;
  padding: 8px 12px; }

.btn--lg {
  font-size: 115%;
  padding: 16px 20px; }

.btn--wide {
  display: block;
  width: 100%; }

.btn.--theme-primary {
  background-color: #1760ae; }
  .btn.--theme-primary:hover {
    background-color: #1d79db; }

.btn.--theme-secondary {
  background-color: #6ea110; }
  .btn.--theme-secondary:hover {
    background-color: #7eb812; }

.btn.--theme-highlight {
  background-color: #fd7e14; }
  .btn.--theme-highlight:hover {
    background-color: #fd9a47; }

.btn.--theme-accent {
  background-color: #ee2927; }
  .btn.--theme-accent:hover {
    background-color: #f25856; }

.btn.--theme-success {
  background-color: #28a745; }
  .btn.--theme-success:hover {
    background-color: #34ce57; }

.btn.--theme-danger {
  background-color: #dc3545; }
  .btn.--theme-danger:hover {
    background-color: #e4606d; }

.btn.--theme-warning {
  background-color: #ffc107; }
  .btn.--theme-warning:hover {
    background-color: #ffce3a; }

.btn.--theme-info {
  background-color: #0182C8; }
  .btn.--theme-info:hover {
    background-color: #01a3fb; }

.button_close {
  padding: 2px 5px 1px;
  border-radius: 3px;
  border: 1px solid #bd2130;
  background-color: #dc3545;
  background-size: 14px 14px;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer; }
  .button_close:hover {
    border-color: #dc3545;
    background-color: #e4606d; }

.btn-mobile {
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -ms-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
  display: inline-block;
  width: auto;
  height: 41px;
  padding: 10px 7px 9px 11px;
  background-color: #1760ae;
  border: none;
  border-radius: 4px;
  color: #fff;
  vertical-align: middle; }
  .btn-mobile > * {
    vertical-align: middle; }
  .btn-mobile:hover, .btn-mobile:focus {
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -ms-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
    outline: none;
    background-color: #1d79db;
    cursor: pointer;
    padding: 10px 11px 9px; }
    .btn-mobile:hover b, .btn-mobile:focus b {
      -webkit-transition: width 300ms;
      -moz-transition: width 300ms;
      -ms-transition: width 300ms;
      -o-transition: width 300ms;
      transition: width 300ms;
      width: 52px;
      margin-left: 5px;
      opacity: 1; }
  .btn-mobile b {
    -webkit-transition: width 300ms;
    -moz-transition: width 300ms;
    -ms-transition: width 300ms;
    -o-transition: width 300ms;
    transition: width 300ms;
    display: inline-block;
    margin-top: -1px;
    width: 0;
    color: #fff;
    font-size: 15px;
    font-size: 0.9375rem;
    font-weight: 800;
    letter-spacing: -0.0475em;
    opacity: 0; }

.button-bars {
  display: inline-block;
  height: 100%;
  width: 26px; }

.icon-bar {
  -webkit-transition: transform ease-in-out 300ms;
  -moz-transition: transform ease-in-out 300ms;
  -ms-transition: transform ease-in-out 300ms;
  -o-transition: transform ease-in-out 300ms;
  transition: transform ease-in-out 300ms;
  display: block;
  width: 100%;
  height: 3px;
  border-radius: 8px;
  background-color: #fff; }
  .icon-bar:nth-child(2) {
    margin: 5px 0; }

.canvas-slid .icon-bar:nth-child(2) {
  visibility: hidden; }

.canvas-slid .icon-bar:nth-child(1), .canvas-slid .icon-bar:nth-child(3) {
  -webkit-transition: transform ease-in-out 150ms;
  -moz-transition: transform ease-in-out 150ms;
  -ms-transition: transform ease-in-out 150ms;
  -o-transition: transform ease-in-out 150ms;
  transition: transform ease-in-out 150ms;
  margin-left: -2px; }

.canvas-slid .icon-bar:nth-child(1) {
  transform: rotate(45deg);
  margin-top: 9px; }

.canvas-slid .icon-bar:nth-child(3) {
  transform: rotate(-45deg);
  margin-top: -16px; }

.canvas-slid .btn-mobile {
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform: scale(0.9);
  padding: 10px 7px 9px 11px !important;
  background-color: #dc3545; }
  .canvas-slid .btn-mobile b {
    display: none !important; }
  .canvas-slid .btn-mobile:hover {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    background-color: #e4606d; }

.company_block {
  text-align: center; }
  .company_block img {
    max-width: 360px;
    margin: 0 auto 15px; }
  @media (min-width: 768px) and (max-width: 991px) {
    .company_block .company_logo {
      float: left;
      width: 49%;
      margin-left: 0; }
    .company_block .company_call,
    .company_block .company_address {
      float: right;
      width: 49%;
      text-align: right; }
    .company_block .list_company {
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      padding: 0.5em; } }
  @media (min-width: 992px) {
    .company_block {
      text-align: left; }
      .company_block .company_logo {
        margin: 0 0 1em; } }

.company_logo {
  margin: 0 auto 0.5em; }

.company_call {
  line-height: 1.1em; }

.company_call .phone {
  font-size: 32px;
  font-weight: 800;
  letter-spacing: -0.025em;
  color: #1760ae; }
  @media (min-width: 992px) {
    .company_call .phone {
      font-size: 42px;
      letter-spacing: -0.05em; } }

.call_emergency {
  display: inline-block;
  margin-top: 0.05em;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.05em;
  color: #dc3545; }

@media (min-width: 992px) {
  .company_address {
    padding: 0.5em 0.75em;
    border-left: 5px solid #ccc; } }

.company_block .list_company {
  clear: both;
  font-weight: 600; }
  .company_block .list_company li {
    display: inline-block;
    padding: 0;
    line-height: 1.25em; }
    .company_block .list_company li:not(:last-child) {
      margin-bottom: 0.25em; }
    .company_block .list_company li span {
      display: inline-block;
      padding: 0.25em 0.5em;
      background-color: rgba(0, 0, 0, 0.7);
      color: #fff; }
  @media (min-width: 992px) {
    .company_block .list_company li {
      display: block; } }

.coverage {
  padding: 1em;
  border: 1px solid #ddd;
  background-color: #fff; }
  .coverage > *:first-child {
    margin-top: 0; }
  .coverage > *:last-child {
    margin-bottom: 0; }

.coverage_title {
  margin-bottom: 0.5em;
  font-size: 17px;
  font-weight: 800;
  text-transform: uppercase; }

.coverage_city {
  font-size: 14px;
  line-height: 1.25em; }

.coverage_county {
  margin-bottom: 0.5em;
  font-size: 15px;
  line-height: 1.32em; }

.form {
  font-size: 1em; }
  .form.form_career textarea {
    height: 180px; }

.form_group-wrap {
  box-shadow: none;
  border: 1px solid #ddd;
  margin: 0 0 1.25rem;
  padding: 0 0.25rem 0.75rem; }
  .form_group-wrap p {
    padding: 0 0.75rem; }

.group_title {
  display: block;
  width: 70%;
  padding: 0.5rem 0.75rem;
  font-size: 1.1rem;
  font-weight: 600;
  background: #F5F5F5; }

.form_security {
  overflow: hidden;
  margin-bottom: 1em; }

.form_title {
  margin: 0 0.75rem 0.5rem;
  font-size: 1.4rem;
  font-weight: 800; }
  .form_title small {
    display: block;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0;
    text-transform: uppercase; }

.form_group {
  padding: 0 0.75em 0.1em; }
  .form_group.form_hp {
    display: none; }

.form_error {
  border: 1px solid #dc3545;
  background-color: #fae3e5; }

.error-message {
  margin: 0 0 0.5em;
  padding: 0 0 0 3px !important;
  font-size: 0.7em;
  font-weight: 800;
  line-height: 1em;
  text-transform: uppercase;
  color: #d32535; }

.form_control {
  display: block;
  width: 100%;
  margin: 0 0 5px;
  padding: 9px 7px;
  font-size: 1rem;
  border: 1px solid #ddd;
  outline: none;
  background-color: #fff; }
  .form_control:focus {
    border-color: #ffc107;
    background-color: #fff4d3; }

textarea.form_control {
  min-height: 100px; }

@media (max-width: 575px) {
  .form_spam {
    position: relative;
    overflow: hidden;
    height: 75px; }
    .form_spam .g-recaptcha {
      -webkit-transform: scale(0.8);
      -moz-transform: scale(0.8);
      -o-transform: scale(0.8);
      transform: scale(0.8);
      position: absolute;
      top: 0;
      left: -18px; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .fix-lg .form_spam {
    position: relative;
    overflow: hidden;
    height: 80px; }
    .fix-lg .form_spam .g-recaptcha {
      -webkit-transform: scale(0.9);
      -moz-transform: scale(0.9);
      -o-transform: scale(0.9);
      transform: scale(0.9);
      position: absolute;
      top: 0;
      left: -2px; } }

.form_submit {
  margin-top: 0.25rem; }

@media (min-width: 768px) {
  .form_lr .form_group {
    padding: 0 0.25em 0.1em; }
  .form_lr .form_left,
  .form_lr .form_right {
    width: 50%; }
  .form_lr .form_left {
    float: left;
    clear: both; }
  .form_lr .form_right {
    float: right; }
  .form_lr .form_clear {
    clear: both; }
  .form_lr .form_state {
    width: 20%;
    clear: none; }
  .form_lr .form_zip {
    width: 30%; } }

@media (min-width: 768px) and (max-width: 991px) {
  .form_lr--sm .form_group {
    padding: 0 0.25em 0.1em; }
  .form_lr--sm .form_left,
  .form_lr--sm .form_right {
    width: 50%; }
  .form_lr--sm .form_left {
    float: left; }
  .form_lr--sm .form_right {
    float: right; }
  .form_lr--sm .form_clear {
    clear: both; } }

.form_quote {
  padding: 0 0 0.75rem;
  border: 1px solid #ddd;
  background-color: #fff; }
  .form_quote .form_title {
    margin: 0 0 0.5rem;
    padding: 0.75rem;
    background-color: #1760ae;
    color: #fff; }

.gallery {
  margin-bottom: 1em; }

.gallery_sidebar img {
  max-width: 360px;
  margin: 0 auto 0.5em; }

.gallery_lightbox img {
  padding: 4px;
  border: 1px solid #ccc;
  background-color: #fff; }

.gallery_lightbox > a {
  margin-bottom: 1%; }

@media (min-width: 576px) and (max-width: 767px) {
  .g2-sm > img, .g2-sm > a {
    display: block;
    float: left;
    width: 49.5%; }
    .g2-sm > img:nth-child(odd), .g2-sm > a:nth-child(odd) {
      clear: both; }
    .g2-sm > img:nth-child(even), .g2-sm > a:nth-child(even) {
      float: right; } }

@media (min-width: 768px) and (max-width: 991px) {
  .g3-md > img, .g3-md > a {
    display: block;
    float: left;
    width: 32.66667%;
    margin-right: 1%; }
    .g3-md > img:nth-child(3n-2), .g3-md > a:nth-child(3n-2) {
      clear: both; }
    .g3-md > img:nth-child(3n), .g3-md > a:nth-child(3n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .g2-lg > img, .g2-lg > a {
    display: block;
    float: left;
    width: 49.5%; }
    .g2-lg > img:nth-child(odd), .g2-lg > a:nth-child(odd) {
      clear: both; }
    .g2-lg > img:nth-child(even), .g2-lg > a:nth-child(even) {
      float: right; } }

@media (min-width: 1200px) {
  .g3-xl > img, .g3-xl > a {
    display: block;
    float: left;
    width: 32.66667%;
    margin-right: 1%; }
    .g3-xl > img:nth-child(3n-2), .g3-xl > a:nth-child(3n-2) {
      clear: both; }
    .g3-xl > img:nth-child(3n), .g3-xl > a:nth-child(3n) {
      float: right;
      margin-right: 0; } }

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 90000;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none; }

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 95000;
  text-align: center;
  line-height: 0;
  font-weight: 400; }

.lightbox .lb-image {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
  border-radius: 3px; }

.lightbox a img {
  border: none; }

.lb-outerContainer {
  position: relative;
  padding: 5px;
  overflow: hidden;
  background-color: white;
  *zoom: 1;
  margin: 0 auto;
  border-radius: 3px 3px 0 0; }

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0; }

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url("/_/images/layout/loading.gif") no-repeat; }

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10; }

.lb-container > .nav {
  left: 0; }

.lb-nav a {
  outline: none;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="); }

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block; }

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url("/_/images/layout/prev.png") left 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -ms-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url("/_/images/layout/next.png") right 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -ms-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-dataContainer {
  margin: 0 auto;
  padding: 7px 0;
  *zoom: 1;
  width: 100%;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: #fff;
  color: #fff; }

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-data {
  padding: 0 4px; }

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em; }

.lb-data .lb-caption {
  display: inline-block;
  padding: 6px 5px 0;
  font-size: 1rem;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1em; }

.lb-data .lb-number {
  display: none !important;
  visibility: hidden !important; }

.lb-data .lb-close {
  display: block;
  float: right;
  margin-right: 5px;
  width: 30px;
  height: 30px;
  background: url("/_/images/icons/close-red.svg") center center no-repeat;
  background-size: 20px 20px;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -ms-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s; }

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.branding_logo {
  display: block;
  width: 100%;
  max-width: 210px;
  margin: 0 auto;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  @media (min-width: 992px) {
    .branding_logo {
      margin: 0; } }
  .page_front .branding_logo {
    max-width: 320px; }

.media_group {
  max-width: 400px;
  margin: 0 auto;
  padding: 15px;
  border: 1px solid #dadada;
  background-color: #fff; }
  .media_group:not(:last-child) {
    margin-bottom: 30px; }
  @media (min-width: 768px) {
    .media_group {
      position: relative;
      max-width: none;
      display: flex;
      flex-wrap: wrap; }
      .media_group .media_image {
        position: absolute;
        top: 15px;
        left: 15px;
        width: 35%;
        margin: 0; }
      .media_group .media_title,
      .media_group .media_text {
        width: 61%;
        margin-left: auto; } }

.media_title {
  padding: 15px;
  background-color: #1760ae;
  color: #fff; }
  @media (min-width: 768px) {
    .media_title {
      margin-bottom: 15px; } }

.media-title {
  margin: 0;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.7); }

.media_image {
  margin-bottom: 15px; }

.product_savings {
  display: inline-block;
  float: right;
  margin: 15px;
  padding: 20px;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1em;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
  border: 2px solid #6ea110;
  box-shadow: 0 20px 15px -20px rgba(0, 0, 0, 0.4);
  background-color: #7eb812;
  color: #fff; }
  .product_savings strong {
    display: block;
    font-weight: 800; }

body.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto; }

.modal {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99000;
  cursor: pointer; }
  .modal.show {
    display: block; }

.modal-dialog {
  position: relative;
  z-index: 99500;
  width: 90%;
  max-width: 640px;
  margin: 20px auto;
  padding: 15px;
  border-radius: 6px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: default; }
  .modal-dialog p {
    margin-bottom: 0;
    padding: 10px 5px;
    font-size: 14px;
    line-height: 1.25em; }
    .modal-dialog p:nth-child(even) {
      background-color: #ddd; }

.modal-header {
  margin: 0 0 15px;
  padding: 0 0 10px;
  border-bottom: 1px solid #ccc; }

.modal-title {
  margin: 15px 0 0;
  font-size: 18px; }

.nav {
  overflow: hidden;
  margin: 0;
  padding: 0;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }

.nav-item {
  box-sizing: border-box;
  list-style-type: none; }
  .nav-item.active .nav-link {
    cursor: default; }
    .nav-item.active .nav-link:hover {
      cursor: default; }
  .nav-item.open .dropdown-nav {
    display: block;
    height: auto; }
  .nav-item.open .caret {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg); }

.nav-link {
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  display: block;
  padding: 12px;
  line-height: 1em;
  text-decoration: none; }
  .nav-link:hover, .nav-link:focus {
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    outline: none; }

.caret {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg); }

.caret,
.caret-push {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  top: 3px;
  vertical-align: top;
  fill: #fff; }

.caret-push {
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  margin-right: 0;
  width: 0; }

.dropdown-nav {
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  display: none;
  position: relative;
  z-index: 95000;
  margin: 0; }
  .dropdown-nav .nav-item {
    list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
    text-align: left; }
  .dropdown-nav .nav-link:hover .caret-push, .dropdown-nav .nav-link:focus .caret-push {
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    width: 10px;
    margin-right: 3px; }

.nav-main {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-content: center; }
  .nav-main .nav-item {
    display: block; }
    .nav-main .nav-item:not(:last-child) {
      padding-right: 2px; }
    .nav-main .nav-item:not(:first-child) {
      padding-left: 2px; }
    .nav-main .nav-item.active .nav-link {
      padding: 10px 18px !important;
      letter-spacing: -0.0325em;
      background: transparent !important;
      color: #1760ae; }
      .nav-main .nav-item.active .nav-link b {
        font-weight: 800; }
  .nav-main > .nav-item > .nav-link {
    border-radius: 4px 4px 0 0;
    background-color: #6ea110; }
    .nav-main > .nav-item > .nav-link:hover {
      background-color: #7eb812;
      padding: 14px 18px; }
  .nav-main .nav-link {
    font-size: 15px;
    font-size: 0.9375rem;
    font-weight: 600;
    padding: 10px 18px;
    color: #fff; }
  .nav-main .dropdown-nav {
    position: absolute;
    min-width: 200px;
    margin-top: 5px;
    padding: 15px 10px;
    border-radius: 4px;
    background-color: #1760ae; }
    .nav-main .dropdown-nav .nav-item:not(:last-child) {
      border-bottom: 1px dotted #fff; }
    .nav-main .dropdown-nav .nav-link:hover {
      color: #6ea110; }
      .nav-main .dropdown-nav .nav-link:hover .caret-push {
        fill: #6ea110; }
    .nav-main .dropdown-nav .active .nav-link {
      color: #fd7e14;
      font-weight: 800; }
      .nav-main .dropdown-nav .active .nav-link .caret-push {
        width: 0;
        margin-right: 0; }

.nav_mobile {
  display: none;
  position: fixed;
  z-index: 999999;
  top: 0;
  right: auto;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  width: 90%;
  max-width: 300px;
  height: 100%;
  padding: 30px 15px;
  border-right: 3px solid #114781;
  background-color: #1760ae;
  color: #fff; }
  .nav_mobile.in {
    display: block; }
  .nav_mobile h4 {
    font-size: 1.2em;
    font-weight: 800;
    text-transform: uppercase; }
  .nav_mobile .nav-item:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.8); }
  .nav_mobile .nav-item:not(:first-child) {
    border-top: 1px solid rgba(255, 255, 255, 0.2); }
  .nav_mobile .nav-item + li.open {
    border-bottom: none !important; }
  .nav_mobile .nav-item.active .nav-link {
    background-color: #fff;
    color: #1760ae; }
    .nav_mobile .nav-item.active .nav-link:hover, .nav_mobile .nav-item.active .nav-link:focus {
      outline: none;
      background-color: #fff;
      color: #1760ae; }
  .nav_mobile .nav-item.open {
    border: none !important; }
    .nav_mobile .nav-item.open + li {
      border-top: none !important; }
    .nav_mobile .nav-item.open > .nav-link {
      background-color: #6ea110; }
  .nav_mobile .nav-link {
    padding: 12px;
    font-weight: 600;
    color: #fff; }
    .nav_mobile .nav-link:hover, .nav_mobile .nav-link:focus {
      outline: none;
      background-color: #6ea110;
      color: #fff; }
  .nav_mobile .dropdown-nav {
    margin: 10px 0;
    min-width: 0;
    background-color: rgba(0, 0, 0, 0.2); }
    .nav_mobile .dropdown-nav .nav-link {
      padding: 12px;
      font-weight: 600;
      background-color: transparent;
      color: #fff; }
      .nav_mobile .dropdown-nav .nav-link:hover, .nav_mobile .dropdown-nav .nav-link:focus {
        outline: none;
        background-color: transparent;
        color: #6ea110; }
        .nav_mobile .dropdown-nav .nav-link:hover .caret-push, .nav_mobile .dropdown-nav .nav-link:focus .caret-push {
          fill: #6ea110; }
  .nav_mobile .mobile-toggle {
    font-size: 1.2em;
    color: #fff;
    text-decoration: none; }
    .nav_mobile .mobile-toggle img {
      display: inline-block;
      width: 0.8em;
      margin-right: 10px; }

.nav-list .nav-item:not(:last-child) {
  border-bottom: 1px dotted #ccc; }

.nav-list .nav-link {
  font-weight: 600;
  padding: 10px;
  color: #1760ae; }
  .nav-list .nav-link small {
    display: block;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.075em; }
  .nav-list .nav-link .caret-push {
    fill: #6ea110; }
  .nav-list .nav-link:hover, .nav-list .nav-link:focus {
    color: #6ea110; }
    .nav-list .nav-link:hover .caret-push, .nav-list .nav-link:focus .caret-push {
      -webkit-transition: all ease-in-out 300ms;
      -moz-transition: all ease-in-out 300ms;
      -ms-transition: all ease-in-out 300ms;
      -o-transition: all ease-in-out 300ms;
      transition: all ease-in-out 300ms;
      width: 10px;
      margin-right: 3px; }

.nav-list .active .nav-link {
  color: #fd7e14;
  font-weight: 800; }
  .nav-list .active .nav-link .caret-push {
    width: 0;
    margin-right: 0; }

.youtube-player {
  position: relative;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000; }
  .youtube-player.yt-16x9 {
    padding-bottom: 56.23%; }
  .youtube-player.yt-4x3 {
    padding-bottom: 75%; }

.youtube-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: transparent; }

.youtube-player img {
  display: block;
  position: absolute;
  z-index: 2000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: auto;
  margin: auto;
  border: none;
  cursor: pointer;
  opacity: 0.7;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .youtube-player img:hover {
    opacity: 1.0; }

.youtube-player .play {
  position: absolute;
  z-index: 5000;
  height: 72px;
  width: 72px;
  left: 50%;
  top: 50%;
  margin-left: -36px;
  margin-top: -36px;
  background: url("/_/images/layout/play.png") no-repeat;
  cursor: pointer; }
  .youtube-player .play:hover + img {
    opacity: 1.0; }

.widget {
  margin: 0 0 15px;
  padding: 15px;
  font-size: 85%;
  border: 1px solid #ddd;
  background-color: #fff; }
  .widget > *:last-child {
    margin-bottom: 0; }
  .widget.widget-default .widget-title {
    color: #333333; }
  .widget.widget-text {
    border-color: #333333; }
    .widget.widget-text .widget-title {
      background-color: #333333; }
  .widget.widget-primary {
    border-color: #1760ae; }
    .widget.widget-primary .widget-title {
      background-color: #1760ae; }
  .widget.widget-secondary {
    border-color: #7eb812; }
    .widget.widget-secondary .widget-title {
      background-color: #7eb812; }
  .widget.widget-highlight {
    border-color: #fd7e14; }
    .widget.widget-highlight .widget-title {
      background-color: #fd7e14; }
  .widget.widget-link {
    border-color: #0b8dd7; }
    .widget.widget-link .widget-title {
      background-color: #0b8dd7; }
  .widget.widget-review {
    border-color: #f90; }
    .widget.widget-review .widget-title {
      background-color: #f90; }
  .widget.widget-success {
    border-color: #28a745; }
    .widget.widget-success .widget-title {
      background-color: #28a745; }
  .widget.widget-danger {
    border-color: #dc3545; }
    .widget.widget-danger .widget-title {
      background-color: #dc3545; }
  .widget.widget-info {
    border-color: #0182C8; }
    .widget.widget-info .widget-title {
      background-color: #0182C8; }
  .widget.widget-warning {
    border-color: #ffc107; }
    .widget.widget-warning .widget-title {
      background-color: #ffc107; }

.widget-title:not(.plain) {
  margin: -15px -15px 15px;
  padding: 12px 15px;
  font-size: 1.5rem;
  font-weight: 600;
  background-color: #ddd;
  color: #fff; }

.widget-title.plain {
  margin: 0 0 5px; }

.widget_plain {
  padding: 0;
  border: none;
  box-shadow: none;
  background-color: transparent; }
  .widget_plain .widget-title {
    margin: 0 0 15px;
    padding: 0px;
    background-color: transparent;
    color: inherit; }

@media (min-width: 768px) and (max-width: 991px) {
  .widget-rwreviews {
    overflow: hidden; }
    .widget-rwreviews p {
      float: left;
      width: 49%; }
      .widget-rwreviews p:nth-child(odd) {
        clear: both; }
      .widget-rwreviews p:nth-child(even) {
        float: right; } }

.widget-pleads {
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  border-width: 3px;
  border-color: #07b; }
  .widget-pleads .widget-title {
    background-color: #07b; }
  .widget-pleads img {
    max-width: 240px;
    margin: 0 auto 0.5em; }

.block {
  padding: 30px; }

.wrap > *:first-child:not(.branding-logo) {
  margin-top: 0; }

.wrap > *:last-child {
  margin-bottom: 0; }

.banner {
  padding-bottom: 0;
  border-bottom: 5px solid #6ea110; }
  .banner .container {
    position: relative;
    padding-bottom: 60px; }
  @media (min-width: 768px) {
    .banner .nav-link {
      font-size: 18px;
      font-size: 1.125rem; } }
  @media (min-width: 992px) {
    .banner .container {
      padding-bottom: 30px; }
    .banner .nav-main {
      justify-content: flex-end; } }

.banner_navigation {
  position: absolute;
  bottom: 0;
  margin-top: 20px; }

.jumbo_marketing {
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 600; }
  @media (min-width: 992px) {
    .jumbo_marketing {
      padding: 45px 30px;
      flex: 0 0 66.66667%;
      max-width: 66.66667%; } }

.page-title {
  position: relative;
  z-index: 70000;
  padding: 2em 0;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4); }
  .page-title h1 {
    margin: 0;
    line-height: 1em;
    text-align: center; }
    .page-title h1 small {
      display: block;
      font-style: italic;
      font-weight: 400;
      letter-spacing: 0.025em; }
  @media (min-width: 768px) {
    .page-title h1 {
      font-size: 2.5rem; } }
  @media (min-width: 992px) {
    .page-title {
      padding: 7em 0 1.5em; }
      .page-title h1 {
        text-align: left; } }
  @media (min-width: 1200px) {
    .page-title h1 {
      font-size: 2.75rem; } }
  .post .page-title h1 {
    max-width: 730px; }

.content {
  padding-top: 2em; }

@media (max-width: 991px) {
  .sidebar {
    padding-top: 15px; } }

@media (min-width: 992px) {
  .sidebar .form_submit input {
    display: block;
    width: 100%; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .sidebar .form_spam {
    position: relative;
    overflow: hidden;
    height: 75px; }
    .sidebar .form_spam .g-recaptcha {
      -webkit-transform: scale(0.85);
      -moz-transform: scale(0.85);
      -o-transform: scale(0.85);
      transform: scale(0.85);
      position: absolute;
      top: 0;
      left: -8px; } }

.sidebar > * {
  margin-bottom: 15px; }

.site_info {
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1em;
  border-top: 5px solid #fff;
  box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.5);
  background-color: #114781;
  color: #fff; }
  .site_info ul > li:not(:last-child) {
    margin: 0 15px 0 0 !important; }

.hidden {
  display: none !important; }

@media (max-width: 575px) {
  .hidden--xs {
    display: none !important; } }

@media (min-width: 576px) and (max-width: 767px) {
  .hidden--sm {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .hidden--md {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden--lg {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden--xl {
    display: none !important; } }

[class*="visible-"] {
  display: none !important; }

@media (max-width: 575px) {
  .visible--xs {
    display: block !important; } }

@media (min-width: 576px) and (max-width: 767px) {
  .visible--sm {
    display: block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible--md {
    display: block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible--lg {
    display: block !important; } }

@media (min-width: 1200px) {
  .visible--xl {
    display: block !important; } }

.db {
  display: block !important; }

.mt0 {
  margin-top: 0 !important; }

.mt5 {
  margin-top: 5px !important; }

.mt10 {
  margin-top: 10px !important; }

.mt15 {
  margin-top: 15px !important; }

.mt20 {
  margin-top: 20px !important; }

.mt30 {
  margin-top: 30px !important; }

.mb0 {
  margin-bottom: 0 !important; }

.mb5 {
  margin-bottom: 5px !important; }

.mb10 {
  margin-bottom: 10px !important; }

.mb15 {
  margin-bottom: 15px !important; }

.mb20 {
  margin-bottom: 20px !important; }

.mb30 {
  margin-bottom: 30px !important; }

.pt0 {
  padding-top: 0 !important; }

.pt10 {
  padding-top: 10px !important; }

.pt15 {
  padding-top: 15px !important; }

.pt20 {
  padding-top: 20px !important; }

.pt30 {
  padding-top: 30px !important; }

.pb0 {
  padding-bottom: 0 !important; }

.pb10 {
  padding-bottom: 10px !important; }

.pb15 {
  padding-bottom: 15px !important; }

.pb20 {
  padding-bottom: 20px !important; }

.pb30 {
  padding-bottom: 30px !important; }

.no-transform {
  transform: none !important; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.ff--serif {
  font-family: "Times New Roman", Georgia, Times, serif !important; }

.ff--sans {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important; }

.txt--lg {
  font-size: 115% !important; }

.txt--md {
  font-size: inherit !important; }

.txt--sm {
  font-size: 85% !important; }

.bw0 {
  font-weight: 400 !important; }

.bw1 {
  font-weight: 600 !important; }

.bw2 {
  font-weight: 800 !important; }

.hw0 {
  font-weight: 400 !important; }

.hw1 {
  font-weight: 600 !important; }

.hw2 {
  font-weight: 800 !important; }

.ls--sm {
  letter-spacing: -0.0375em; }

.ls--rg {
  letter-spacing: 0; }

.ls--lg {
  letter-spacing: 0.275em; }

.lh1 {
  line-height: 1.1em !important; }

.lh125 {
  line-height: 1.25em !important; }

.txt--left {
  text-align: left !important; }

.txt--right {
  text-align: right !important; }

.txt--center {
  text-align: center !important; }

.txt--up {
  text-transform: uppercase !important; }

.txt--cap {
  text-transform: capitalize !important; }

.color--text {
  color: #333333 !important; }

.color--white {
  color: #fff !important; }

.color--primary {
  color: #1760ae !important; }

.color--secondary {
  color: #7eb812 !important; }

.color--highlight {
  color: #fd7e14 !important; }

.color--accent {
  color: #ee2927 !important; }

.color--link {
  color: #0b8dd7 !important; }

.color--review {
  color: #f90 !important; }

.color--success {
  color: #28a745 !important; }

.color--danger {
  color: #dc3545 !important; }

.color--warning {
  color: #ffc107 !important; }

.color--info {
  color: #0182C8 !important; }

.bg--text {
  background-color: #333333 !important; }

.bg--white {
  background-color: #fff !important; }

.bg--primary {
  background-color: #1760ae !important; }

.bg--secondary {
  background-color: #7eb812 !important; }

.bg--highlight {
  background-color: #fd7e14 !important; }

.bg--link {
  background-color: #0b8dd7 !important; }

.bg--review {
  background-color: #f90 !important; }

.bg--success {
  background-color: #28a745 !important; }

.bg--danger {
  background-color: #dc3545 !important; }

.bg--warning {
  background-color: #ffc107 !important; }

.bg--info {
  background-color: #0182C8 !important; }

.bg--transparent {
  background-color: transparent !important; }

.bg--body {
  background-color: #F5F5F5 !important; }

.gdfancybg--text {
  background: #333333;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #333333; }

.gdfancybg--white {
  background: #fff;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #fff; }

.gdfancybg--primary {
  background: #1760ae;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #1760ae; }

.gdfancybg--secondary {
  background: #7eb812;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #7eb812; }

.gdfancybg--highlight {
  background: #fd7e14;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #fd7e14; }

.gdfancybg--review {
  background: #f90;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #f90; }

.gdfancybg--success {
  background: #28a745;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #28a745; }

.gdfancybg--danger {
  background: #dc3545;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #dc3545; }

.gdfancybg--warning {
  background: #ffc107;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #ffc107; }

.gdfancybg--info {
  background: #0182C8;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #0182C8; }
