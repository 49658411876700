.media_group {
	
	max-width: 400px;
	margin: 0 auto;
	padding: 15px;
	border: 1px solid #dadada;
	background-color: $white;

	&:not(:last-child) { margin-bottom: 30px; }

	@include media-breakpoint-up(md) {
		position: relative;
		max-width: none;
		@include make-flex;
		.media_image {
			position: absolute;
			top: 15px;
			left: 15px;
			width: 35%;
			margin: 0;
		}
		.media_title,
		.media_text {
			width: 61%;
			margin-left: auto;
		}
	}

}

	.media_title {
		padding: 15px;
		background-color: $color-primary;
		color: $white;
		@include media-breakpoint-up(md) { margin-bottom: 15px; }
	}

		.media-title {
			margin: 0;
			text-shadow: 0 1px 2px rgba(black,0.7);
		}

	.media_image {
		margin-bottom: 15px;
	}

		.product_savings {
			display: inline-block;
			float: right;
			margin: 15px;
			padding: 20px;
			@include font-size(20);
			font-weight: $body-weight-bold;
			line-height: 1em;
			text-shadow: 0 1px 1px rgba(black,0.4);
			border: 2px solid theme(secondary, dark);
  			box-shadow: 0 20px 15px -20px rgba(0, 0, 0, 0.4);
			background-color: $color-secondary;
			color: $white;

			strong {
				display: block;
				font-weight: $body-weight-bolder;
			}
		}
