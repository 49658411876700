$site_info_bg: 			$color-primary;
$site_info_color: 		$white;


.site_info {

	@include font-size(15);
	line-height: 1em;

	border-top: 5px solid $white;
	box-shadow: 0 -2px 5px 0 rgba(0,0,0,0.5);
	background-color: theme(primary, dark);
	color: $white;

	ul > li:not(:last-child) {
		margin: 0 15px 0 0 !important;
	}

}
