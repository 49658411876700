.jumbo_marketing {

	@include font-size(24);
	font-weight: $body-weight-bold;

	@include media-breakpoint-up(lg) {
		padding: 45px 30px;
		@include make-col(8);
	}

}