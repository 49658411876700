$mobile-button-bg: 			transparent !default;
$toggle-bg: 				theme(primary, base) !default;
$toggle-color: 				$white !default;
$toggle-hover-bg: 			theme(primary, light) !default;

.btn-mobile {
	@include transition(all 300ms);
	display: inline-block;
	width: auto; height: 41px;
	padding: 10px 7px 9px 11px;
	background-color: $toggle-bg;
	border: none;
	border-radius: 4px;
	color: $toggle-color;
	vertical-align: middle;
	> * { vertical-align: middle; }

	&:hover, &:focus {
		@include transition(all 300ms);
		outline: none;
		background-color: $toggle-hover-bg;
		cursor: pointer;
		padding: 10px 11px 9px;
		b {
			@include transition(width 300ms);
			width: 52px;
			margin-left: 5px;
			opacity: 1;
		}
	}

	b {
		@include transition(width 300ms);
		display: inline-block;
		margin-top: -1px;
		width: 0;
		color: $white;
		@include font-size(15);
		font-weight: $body-weight-bolder;
		letter-spacing: -0.0475em;
		opacity: 0;
	}

}

	.button-bars {
		display: inline-block;
		height: 100%;
		width: 26px;
	}

		.icon-bar {
			@include transition(transform ease-in-out 300ms);
			display: block;
			width: 100%;
			height: 3px;
			border-radius: 8px;
			background-color: $toggle-color;
			&:nth-child(2) { margin: 5px 0; }
		}


.canvas-slid {
	.icon-bar {
		&:nth-child(2) { visibility: hidden; }
		&:nth-child(1),
		&:nth-child(3) {
			@include transition(transform ease-in-out 150ms);
			margin-left: -2px;
		}
		&:nth-child(1) { transform: rotate(45deg); margin-top: 9px; }
		&:nth-child(3) { transform: rotate(-45deg); margin-top: -16px; }
	}

	.btn-mobile {
		@include transform(scale(0.9));
		padding: 10px 7px 9px 11px !important;
		background-color: theme(danger, base);
		b {	display: none !important; }
		&:hover {
			@include transform(scale(1));
			background-color: theme(danger, light);
		}
	}	

}
