.nav-list {

	.nav-item:not(:last-child) {
		border-bottom: 1px dotted #ccc;
	}

	.nav-link {

		font-weight: $body-weight-bold;
		padding: 10px;
		color: $color-primary;

		small {
			display: block;
			font-weight: $body-weight-normal;
			text-transform: uppercase;
			letter-spacing: 0.075em;
		}

		.caret-push { fill: theme(secondary, dark); }

		&:hover, &:focus {
			color: theme(secondary, dark);
			.caret-push {
				@include transition(all ease-in-out 300ms);
				width: 10px;
				margin-right: 3px;
			}
		}				
	}

	.active .nav-link {
		color: theme(highlight, base);
		font-weight: $body-weight-bolder;
		.caret-push {
			width: 0;
			margin-right: 0;
		}
	}

}

