$table-border-width:        1px;
$table-border-color:        #ccc;
$table-accent-bg:           #f3f3f3;
$table-hover-bg:            #e5e5e5;
$table-cell-padding:        0.5em 0.75em;

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 15px;
    line-height: 1em;
    border-spacing: 0;
    border-collapse: collapse;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    * {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;    
    }

    th,
    td {
        padding: $table-cell-padding;
        vertical-align: top;
        border-top: $table-border-width solid $table-border-color;
    }

    thead th {
        vertical-align: bottom;
        border-bottom: (2 * $table-border-width) solid $table-border-color;
    }

    tbody + tbody {
        border-top: (2 * $table-border-width) solid $table-border-color;
    }

    &.--right {
        @include media-breakpoint-up(md) {
            display: block;
            width: 40%;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: -ms-autohiding-scrollbar;            
            float: right;
        }
    }

}

.table-bordered {
    border: (2 * $table-border-width) solid $table-border-color;

    th, td { border: $table-border-width solid $table-border-color; }

    thead {
        th, td { border-bottom-width: (2 * $table-border-width); }
    }
}

.table-striped {
    tbody tr:nth-of-type(odd) { background-color: $table-accent-bg; }
}

.table-hover {
    tbody tr:hover {
        background-color: $table-hover-bg;
        cursor: pointer;
    }
}

.table-responsive {
    @include media-breakpoint-down(sm) {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        > .table-bordered {
            border: 0;
        }        
    }
}
