// ===========================================
// BOOTSTRAP'S GRID
// ===========================================

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);
@include _assert-ascending($container-max-widths, "$container-max-widths");

.container {
	@include make-container();
	@include make-container-max-widths();
}

.container-fluid {
	width: 100%;
	@include make-container();
}

.row {
	@include make-row();
}

.no-gutters {
	margin-right: 0;
	margin-left: 0;
	> .wrap {
		padding-right: 0;
		padding-left: 0;
	}
}


// ===========================================
// BACE GRID
// ===========================================

html {
	@include prefix('box-sizing', 'border-box', webkit moz ms o);
    -ms-overflow-style: scrollbar;
}

	*,
	*:before,
	*:after {
		@include prefix('box-sizing', 'inherit', webkit moz ms o);
	}

	.wrap { @include make-col-ready(); }


	
// ==================================================
// BLOCKS
// ==================================================

.content {
	
	@include media-breakpoint-up(lg) {
		.main { @include make-col(8); padding: 0 15px; }
		.sidebar { @include make-col(4); padding: 0 15px; }
	}

	&.full_width {
		@include media-breakpoint-up(lg) {
			.main { @include make-col(12); }
		}
	}

}