.banner {
	padding-bottom: 0;
	border-bottom: 5px solid theme(secondary, dark);
	.container {
		position: relative;
		padding-bottom: 60px;
	}

	@include media-breakpoint-up(md) {
		.nav-link { @include font-size(18); }
	}

	@include media-breakpoint-up(lg) {
		.container {
			padding-bottom: 30px;
		}
		.nav-main {
			justify-content: flex-end;
		}
	}

}

	.banner_navigation {
		position: absolute;
		bottom: 0;
		margin-top: 20px;
	}