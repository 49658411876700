//.title_branding {}

	.branding_logo {

		display: block;
		width: 100%;
		max-width: 210px;
		margin: 0 auto;
 		@include transition(all ease-in-out 300ms);
		
		@include media-breakpoint-up(lg) { margin: 0 }

		.page_front & { max-width: 320px }

	}