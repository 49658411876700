@mixin btn_theme($color: primary, $from: base, $to: light) {
	background-color: theme($color, $from);
	&:hover {
		background-color: theme($color, $to);
	}
}

@mixin btn_solid($color: $color-link, $lite: 15%) {
	background-color: $color;
	&:hover {
		background-color: lighten($color, $lite);
	}
}

.btn {
	display: inline-block;
	padding: 12px 15px;
	font-family: $font-family-sans-serif;
	font-weight: $body-weight-bold;
	line-height: 1em;
	text-decoration: none;
	text-shadow: 0 1px 1px rgba(black,0.2);
	border-radius: 4px;
	border: none;
	background-color: $color-link;
	color: $white !important;
	@include transition(all 200ms);

	&.--has-icon svg { margin: -2px 5px 0 0; }

	b { font-weight: $body-weight-bolder; display: inline-block; }

	&:not(.btn-submit, .no-grow) {
		@include transform(scale(0.96));
		&:hover { @include transform(scale(1)); }
	}

	&:hover {
		background-color: lighten($color-link, 15%);
		box-shadow: 0 15px 10px -15px rgba(0, 0, 0, 0.4);
		color: $white;
		cursor: pointer;
		text-shadow: 0 0 2px rgba(black,0.3);
		@include transition(all 200ms);
	}

}

	.btn--sm {
		font-size: 85%;
		padding: 8px 12px;
	}

	.btn--lg {
		font-size: 115%;
		padding: 16px 20px;
	}

	.btn--wide {
		display: block;
		width: 100%;
	}

	.btn.--theme-primary { @include btn_theme(primary); }
	.btn.--theme-secondary { @include btn_theme(secondary, dark, base); }
	.btn.--theme-highlight { @include btn_theme(highlight); }
	.btn.--theme-accent { @include btn_theme(accent); }
	.btn.--theme-success { @include btn_theme(success); }
	.btn.--theme-danger { @include btn_theme(danger); }
	.btn.--theme-warning { @include btn_theme(warning); }
	.btn.--theme-info { @include btn_theme(info); }