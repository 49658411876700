.nav {
	@extend %list_unstyled;
	overflow: hidden;
	margin: 0;
	padding: 0;
	font-family: $font-family-sans-serif;
}

	.nav-item {
		box-sizing: border-box;
		list-style-type: none;
		&.active .nav-link {
			cursor: default;
			&:hover { cursor: default; }
		}
		&.open {
			.dropdown-nav {
				display: block;
				height: auto;
			}
			.caret {
				@include transform(rotate(-90deg));
			}
		}
	}

		.nav-link {
			@include transition(all ease-in-out 200ms);
			display: block;
			padding: 12px;
			line-height: 1em;
			text-decoration: none;
			&:hover,
			&:focus {
				@include transition(all ease-in-out 200ms);
				outline: none;
			}
		}

			.caret { @include transform(rotate(90deg)); }

			.caret,
			.caret-push {
				position: relative;
				display: inline-block;
				width: 10px;
				height: 10px;
				top: 3px;
				vertical-align: top;
				fill: $white;
			}

			.caret-push {
				@include transition(all ease-in-out 200ms);
				margin-right: 0;
				width: 0;
			}

		.dropdown-nav {
			@include transition(all ease-in-out 200ms);
			display: none;
			position: relative;
			z-index: 95000;
			margin: 0;
			.nav-item {
				list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
				text-align: left;
			}
			.nav-link {
				&:hover, &:focus {
					.caret-push {
						@include transition(all ease-in-out 200ms);
						width: 10px;
						margin-right: 3px;
					}
				}				
			}
		}