	.button_close {
		padding: 2px 5px 1px;
		border-radius: 3px;
		border: 1px solid theme(danger, dark);
		background-color: theme(danger, base);
		background-size: 14px 14px;
		background-position: center center;
		background-repeat: no-repeat;
		cursor: pointer;
		
		&:hover {
			border-color: theme(danger, base);
			background-color: theme(danger, light);
		}
	}