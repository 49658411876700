
$nav-mobile-bg: 			theme(primary, base) !default;
$mobile-link-color:			$white !default;
$mobile-hover-bg: 			theme(secondary, dark) !default;
$mobile-hover-color: 		$white !default;
$mobile-active-bg: 			$white !default;
$mobile-active-color: 		theme(primary, base) !default;

$nav-line-dark: 0.8;
$nav-line-lite: 0.2;

$mobile-dropwdown-bg: 		rgba(black,0.2); //$mobile-hover-bg;
	$drop-line-dark: 0.4;
	$drop-line-lite: 0.6;
	$drop-link-bg: transparent;
	$drop-link-color: $white;
	$drop-hover-bg: transparent;
	$drop-hover-color: theme(secondary, dark);

@mixin offcanvas {
	display: none;
	&.in { display: block; }
}

.nav_mobile {

	@include offcanvas;
	position: fixed;
	z-index: 999999;
	top: 0; right: auto;
	bottom: 0; left: 0;
	overflow-y: auto;
	width: 90%;
	max-width: 300px;
	height: 100%;
	padding: 30px 15px;
	border-right: 3px solid theme(primary, dark);
	background-color: $nav-mobile-bg;
	color: $white;

	h4 {
		font-size: 1.2em;
		font-weight: 800;
		text-transform: uppercase;
	}

	.nav-item:not(:last-child) { border-bottom: 1px solid rgba(black,$nav-line-dark); }
	.nav-item:not(:first-child) { border-top: 1px solid rgba(white,$nav-line-lite); }

	.nav-item {


		+ li.open { border-bottom: none !important; }

		&.active .nav-link {
			background-color: $mobile-active-bg;
			color: $mobile-active-color;
			&:hover, &:focus {
				outline: none;
				background-color: $mobile-active-bg;
				color: $mobile-active-color;
			}
		}

		&.open {
			border: none !important;
			+ li { border-top: none !important; }
			> .nav-link {
				background-color: $mobile-hover-bg;
			}
		}

	}	

	.nav-link {
		padding: 12px;
		font-weight: 600;
		color: $mobile-link-color;
		&:hover, &:focus {
			outline: none;
			background-color: $mobile-hover-bg;
			color: $mobile-hover-color;
		}
	}

	.dropdown-nav {
		margin: 10px 0;
		min-width: 0;
		background-color: $mobile-dropwdown-bg;

		//.nav-item:not(:last-child) { border-bottom: 1px solid rgba(black,$drop-line-dark); }
		//.nav-item:not(:first-child) { border-top: 1px solid rgba(white,$drop-line-lite); }

		.nav-link {
			padding: 12px;
			font-weight: 600;
			background-color: $drop-link-bg;
			color: $drop-link-color;
			&:hover, &:focus {
				outline: none;
				background-color: $drop-hover-bg;
				color: $drop-hover-color;
				.caret-push { fill: $drop-hover-color; }
			}				
		}

	}

	.mobile-toggle {

		font-size: 1.2em;
		color: #fff;
		text-decoration: none;

		img {
			display: inline-block;
			width: 0.8em;
			margin-right: 10px;
		}

	}

}

